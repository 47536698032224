import React, { useEffect, useState } from 'react';
import Carousel from './Carousel/Carousel';
import Modal from './Modal/Modal';
import { CardInfo } from './Card/Card';
import { CategorizedData } from '../App';

interface CategoryDataProps {
  categoryData: CategorizedData;
  areaName: string;
}

const Category: React.FC<CategoryDataProps> = ({ categoryData, areaName }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalPositionY, setModalPositionY] = useState(0);
  const [selectedCard, setSelectedCard] = useState<CardInfo>({
    sSTSReportAreaName: '',
    sSTSReportAreaCategory: '',
    sCourseNumber: '',
    sCourseName: '',
    mCourseDescription: '',
    sCourseMinutes: '0',
    sCourseDeliveryTypeAbbrev: '',
    sPreReqNumbers: '',
    sPreReqNames: '',
    sCrossCreditNumbers: '',
    sCrossCreditNames: '',
    sDivisions: '',
  });

  return (
    <div className=''>
      {showModal && (
        <Modal
          setShowModal={setShowModal}
          selectedCard={selectedCard}
          modalPositionY={modalPositionY}
        />
      )}
      {categoryData[areaName]
        ? Object.keys(categoryData[areaName])
            .sort((a, b) => {
              // If 'STS' is a or b, move it to the end, since we want STS to always be at the end
              if (
                a.toLowerCase().includes('sts') &&
                !b.toLowerCase().includes('sts')
              ) {
                return 1;
              } else if (
                !a.toLowerCase().includes('sts') &&
                b.toLowerCase().includes('sts')
              ) {
                return -1;
              }

              return 0;
            })
            .map((category, index: number) => {
              return (
                <Carousel
                  key={index}
                  title={category}
                  courseData={categoryData[areaName][category]}
                  setShowModal={setShowModal}
                  setSelectedCard={setSelectedCard}
                  setModalPositionY={setModalPositionY}
                />
              );
            })
        : null}
    </div>
  );

  // return (
  //   <div className=''>
  //     {showModal && (
  //       <Modal setShowModal={setShowModal} selectedCard={selectedCard} />
  //     )}
  //     {Object.keys(categoryData).map((areaName) => {
  //       return (
  //         <>
  //           <h2 className="area-name">{areaName}</h2>
  //           {Object.keys(categoryData[areaName]).map((category) => {
  //             return (
  //               <Carousel
  //                 title={category}
  //                 courseData={categoryData[areaName][category]}
  //                 setShowModal={setShowModal}
  //                 setSelectedCard={setSelectedCard}
  //               />
  //             );
  //           })}
  //         </>
  //       );
  //     })}

  //     {/* if category is Fundamentals  <Slider courseData={dummydata} title='Bronze' /> */}
  //     {/* conditionally render the sliders based on the category */}
  //     {/* <Carousel courseData={dummydata} title='Silver' /> */}
  //     {/* <Carousel courseData={dummydata} title='Silver Future' />
  //     <Carousel courseData={dummydata} title='Gold' />
  //     <Carousel courseData={dummydata} title='Gold Future' />
  //     <Carousel courseData={dummydata} title='MTC' /> */}
  //   </div>
  // );
};

export default Category;
