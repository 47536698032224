import Category from './components/Category';
import { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';
import axiosRetry from 'axios-retry';
//import categoryTempData from './assets/tempData.json';

// Components
import Loader from './components/Loader/Loader';

export interface CategorizedData {
  [key: string]: {
    [key: string]: Object[];
  };
}

export interface CatalogData {
  iCourseID: number;
  iFullCourseID: number;
  iSTSReportAreaCategoryID: number;
  iSTSReportAreaID: number;
  mCourseDescription: string;
  sCourseDeliveryTypeAbbrev: string;
  sCourseMinutes: string;
  sCourseName: string;
  sCourseNumber: string;
  sCrossCreditNames: string;
  sCrossCreditNumbers: string;
  sDivisions: string;
  sPreReqNames: string;
  sPreReqNumbers: string;
  sSTSReportAreaCategory: string;
  sSTSReportAreaName: string;
  stsRequirements?: string[] | undefined;
}

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

function App() {
  const [areaName, setAreaName] = useState<string>('Fundamentals');
  const [catalogData, setCatalogData] = useState<CatalogData[]>([]);
  const [categorizedData, setCategorizedData] = useState<CategorizedData>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const generateSTSRequirements = (catalog: CatalogData) => {
    const divisions = catalog.sDivisions.split('|');
    const requirementsArr: string[] = [];

    divisions.forEach((division) => {
      switch (division.toLowerCase()) {
        case 'buick':
          requirementsArr.push('B');
          break;
        case 'cadillac':
          requirementsArr.push('K');
          break;
        case 'chev ev enrolled (258)':
          requirementsArr.push('C-EV');
          break;
        case 'chevrolet':
          requirementsArr.push('C');
          break;
        case 'gmc':
          requirementsArr.push('G');
          break;
        case 'gmc hummer ev':
          requirementsArr.push('G-EV');
          break;
      }
    });

    return requirementsArr;
  };

  const retrieveCatalogData = async () => {
    setIsLoading(true);
    try {
      // const gmAPIResponse = await axios.post(
      //   '/?rest_route=/gmsts/catalog/areaname',
      //   { areaName: areaName }
      // );
      // This is for production
      const gmAPIResponse = await axios.post(
        'https://beta.gmstc.com/?rest_route=/gmsts/catalog/areaname',
        { areaName: areaName }
      );
      console.log(gmAPIResponse);
      if (!gmAPIResponse.data) {
        setIsLoading(false);
        return;
      }
      // console.log(gmAPIResponse.data);
      const dataWithSTS = gmAPIResponse.data.map((catalog: CatalogData) => {
        catalog.stsRequirements = generateSTSRequirements(catalog);
        return catalog;
      });
      setCatalogData(dataWithSTS);
      setIsLoading(false);
    } catch (error) {
      console.error('Got an Axios Error', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    retrieveCatalogData();
  }, []);

  useEffect(() => {
    catalogData.forEach((data) => {
      data.stsRequirements = generateSTSRequirements(data);
    });
  }, [catalogData]);

  useEffect(() => {
    // Generate dictionary for
    const categorizedData: {
      [key: string]: {
        [key: string]: Object[];
      };
    } = {};
    catalogData.forEach((course) => {
      // // Extract the AreaCategory
      // const areaCategory: string = course.AreaCategory;

      // // Check if the category already exists in the dictionary
      // if (!categorizedData[areaCategory]) {
      //   // If not, create an array for the category
      //   categorizedData[areaCategory] = [];
      // }

      // // Add the course data to the array under the respective category
      // categorizedData[areaCategory].push(course);

      // Extract the AreaName and AreaCategory
      const areaName: string = course.sSTSReportAreaName.trim();
      const areaCategory: string = course.sSTSReportAreaCategory;

      // Check if the areaName exists in the dictionary
      if (!categorizedData[areaName]) {
        // If not, create a dictionary for the areaName
        categorizedData[areaName] = {};
      }

      // Check if the areaCategory exists in the inner dictionary
      if (!categorizedData[areaName][areaCategory]) {
        // If not, create an array for the areaCategory
        categorizedData[areaName][areaCategory] = [];
      }

      // Add the course data to the array under the respective category
      categorizedData[areaName][areaCategory].push(course);
    });
    setCategorizedData(categorizedData);
  }, [catalogData]);

  // useEffect(() => {
  //   // Generate dictionary for
  //   const categorizedData: {
  //     [key: string]: {
  //       [key: string]: Object[];
  //     };
  //   } = {};
  //   (categoryTempData as []).forEach((course: any) => {
  //     // // Extract the AreaCategory
  //     // const areaCategory: string = course.AreaCategory;

  //     // // Check if the category already exists in the dictionary
  //     // if (!categorizedData[areaCategory]) {
  //     //   // If not, create an array for the category
  //     //   categorizedData[areaCategory] = [];
  //     // }

  //     // // Add the course data to the array under the respective category
  //     // categorizedData[areaCategory].push(course);

  //     // Extract the AreaName and AreaCategory
  //     const areaName: string = course.sSTSReportAreaName;
  //     const areaCategory: string = course.sSTSReportAreaCategory;

  //     // Check if the areaName exists in the dictionary
  //     if (!categorizedData[areaName]) {
  //       // If not, create a dictionary for the areaName
  //       categorizedData[areaName] = {};
  //     }

  //     // Check if the areaCategory exists in the inner dictionary
  //     if (!categorizedData[areaName][areaCategory]) {
  //       // If not, create an array for the areaCategory
  //       categorizedData[areaName][areaCategory] = [];
  //     }

  //     // Add the course data to the array under the respective category
  //     categorizedData[areaName][areaCategory].push(course);
  //   });
  //   setCategorizedData(categorizedData);
  // }, [categoryTempData]);

  return (
    <div className='App'>
      Herro
      {isLoading ? (
        <div className='loader-container'>
          <Loader />
        </div>
      ) : (
        <Category categoryData={categorizedData} areaName={areaName} />
      )}
    </div>
  );
}

export default App;
