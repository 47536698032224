import { Dispatch, SetStateAction } from 'react';
import './card.css';

export interface CardInfo {
  sSTSReportAreaName: string;
  sSTSReportAreaCategory: string;
  sCourseNumber: string;
  sCourseName: string;
  mCourseDescription: string;
  sCourseMinutes: string;
  sCourseDeliveryTypeAbbrev: string;
  sPreReqNumbers: string;
  sPreReqNames: string;
  sCrossCreditNumbers: string;
  sCrossCreditNames: string;
  sDivisions: string;
}

interface CardProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setSelectedCard: Dispatch<SetStateAction<any>>;
  cardData: any;
}

const Card = ({ setShowModal, setSelectedCard, cardData }: CardProps) => {
  const {
    sSTSReportAreaName,
    sSTSReportAreaCategory,
    sCourseNumber,
    sCourseName,
    mCourseDescription,
    sCourseMinutes,
    sCourseDeliveryTypeAbbrev,
    sPreReqNumbers,
    sPreReqNames,
    sCrossCreditNumbers,
    sCrossCreditNames,
    sDivisions,
    stsRequirements,
  } = cardData;

  const getCardColor = (deliveryType: string) => {
    switch (deliveryType.toLowerCase()) {
      case 'wbt':
        return 'wbt-line-border';
      case 'vod':
        return 'vod-line-border';
      case 'vct':
        return 'vct-line-border';
      case 'placement test':
        return 'placement-test-line-border';
      case 'hands on':
        return 'hands-on-line-border';
      case 'certification assessment':
        return 'certification-assessment-line-border';
      default:
        return 'default-line-border';
    }
  };

  const handleCardClick = (e: any) => {
    setShowModal(true);
    setSelectedCard(cardData);
  };

  return (
    <div className='card-wrapper' onClick={handleCardClick}>
      <div className='dotted-border'></div>
      <div className='solid-outer-border'></div>
      <div className='silver-line-border'></div>
      <div
        className={getCardColor(sCourseDeliveryTypeAbbrev) + ' line-border'}
      ></div>
      <div className='text-container'>
        <h3 className='card-course-number'>{sCourseNumber}</h3>
        <p className='card-course-name'>{sCourseName}</p>
        {stsRequirements.length ? (
          <>
            <p className='card-sts-requirement'>STS REQUIREMENTS:</p>
            <p>{stsRequirements.join(', ')}</p>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Card;
