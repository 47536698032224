import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import './Carousel.css';
import Card, { CardInfo } from '../Card/Card';

interface CarouselProps {
  key: number;
  courseData: Object[];
  title: string;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setSelectedCard: Dispatch<SetStateAction<CardInfo>>;
  setModalPositionY: Dispatch<SetStateAction<number>>;
}

const Carousel: React.FC<CarouselProps> = ({
  courseData,
  title,
  setShowModal,
  setSelectedCard,
  setModalPositionY,
}) => {
  const carouselRef = useRef(null);
  const [itemsToShow, setItemsToShow] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lastPossibleIndex, setLastPossibleIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [topRowData, setTopRowData] = useState<Object[]>([]);
  const [bottomRowData, setBottomRowData] = useState<Object[]>([]);
  const [windowSize, setWindowSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const carousel: any = carouselRef.current;
    const itemsPerScreen = parseInt(
      getComputedStyle(carousel).getPropertyValue('--items-per-screen')
    );
    setItemsToShow(itemsPerScreen);
    setLastPossibleIndex(Math.floor(courseData.length / (itemsToShow * 2)));
    itemsToShow &&
      setPageCount(Math.floor(courseData.length / (itemsToShow * 2)));
  }, [currentIndex, itemsToShow, windowSize]);

  const handlePrevClick = () => {
    const newIndex = Math.max(0, currentIndex - itemsToShow);
    setCurrentPage((prevPage) => {
      return (prevPage = prevPage - 1);
    });
    setCurrentIndex(newIndex);
  };

  const handleNextClick = () => {
    const newIndex = Math.min(
      currentIndex + itemsToShow,
      courseData.length - itemsToShow
    );
    setCurrentPage((prevPage) => {
      return (prevPage = prevPage + 1);
    });
    setCurrentIndex(newIndex);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    if (page === 0) {
      setCurrentIndex(page);
    } else {
      setCurrentIndex(page + page * 2);
    }
  };

  const splitDataIntoTopAndBottom = () => {
    // Split the data between topRowData and bottomRowData
    // Loop through course data.
    const topData: Object[] = [];
    const bottomData: Object[] = [];
    if (itemsToShow > 0) {
      let topSide: boolean = true;
      let sideIndex: number = itemsToShow;
      courseData.forEach((data, index) => {
        if (index >= sideIndex) {
          sideIndex += itemsToShow;
          topSide = !topSide;
        }

        if (topSide) {
          topData.push(data);
        } else {
          bottomData.push(data);
        }
      });
    }

    setTopRowData(topData);
    setBottomRowData(bottomData);
  };

  useEffect(() => {
    splitDataIntoTopAndBottom();
  }, [currentIndex, itemsToShow]);

  useEffect(() => {
    const windowResize = () => {
      setWindowSize(window.innerWidth);
    };
    // Attach the event listener to the window object
    window.addEventListener('resize', windowResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', windowResize);
    };
  }, [itemsToShow]);

  const generatePagination = () => {
    const paginationElem = [];
    for (let i = 0; i <= pageCount; i++) {
      paginationElem.push(
        <button
          key={i}
          className={`pagination-button ${currentPage === i ? 'active' : ''}`}
          onClick={() => onPageChange(i)}
        ></button>
      );
    }

    return paginationElem;
  };

  return (
    <div className='carousel-container' ref={carouselRef}>
      <div
        className='carousel'
        onClick={(e: any) => {
          // Temporary fix for modal positioning on different screen heights
          if (e.pageY < document.documentElement.clientHeight - 350) {
            // setModalPositionY(e.pageY + 125);
            setModalPositionY(e.pageY + 150);
          } else if (document.documentElement.clientHeight < 400) {
            setModalPositionY(160);
          } else if (document.documentElement.clientHeight < 600) {
            setModalPositionY(220);
          } else {
            setModalPositionY(document.documentElement.clientHeight - 350);
          }
        }}
      >
        <div className='carousel-title'>
          <h3>{title === 'STS' ? 'MTC' : title}</h3>
        </div>
        <div
          className='carousel-inner'
          style={{
            transform: `translateX(-${currentIndex * (100 / itemsToShow)}%)`,
          }}
        >
          {topRowData.map((item: any, index: number) => {
            return (
              <div key={index} className='carousel-item'>
                <Card
                  setShowModal={setShowModal}
                  setSelectedCard={setSelectedCard}
                  cardData={item}
                />
                {item.title}
              </div>
            );
          })}
        </div>
        {bottomRowData.length ? (
          <div
            className='carousel-inner carousel-bottom-row'
            style={{
              transform: `translateX(-${currentIndex * (100 / itemsToShow)}%)`,
            }}
          >
            {bottomRowData.map((item: any, index: number) => {
              return (
                <div key={index} className='carousel-item'>
                  <Card
                    setShowModal={setShowModal}
                    setSelectedCard={setSelectedCard}
                    cardData={item}
                  />
                  {item.title}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      {pageCount >= 1 ? (
        <div className='pagination-bar'>{generatePagination()}</div>
      ) : null}
      {pageCount >= 1 && currentIndex > 0 && (
        <button className='prev-button' onClick={handlePrevClick}>
          <ArrowCircleLeftIcon />
        </button>
      )}
      {pageCount >= 1 && currentPage !== lastPossibleIndex && (
        <button className='next-button' onClick={handleNextClick}>
          <ArrowCircleRightIcon />
        </button>
      )}
    </div>
  );
};

export default Carousel;
