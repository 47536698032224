import React, { Dispatch, SetStateAction, useEffect } from 'react';
import './modal.css';
import CloseIcon from '@mui/icons-material/Close';
import { CardInfo } from '../Card/Card';

interface ModalProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  selectedCard: CardInfo;
  modalPositionY: number;
}

const Modal = ({ setShowModal, selectedCard, modalPositionY }: ModalProps) => {
  // Prevents scrolling when modal is open
  useEffect(() => {
    const body = document.querySelector('body');
    const preReqDesc = document.getElementById('modal-description');
    if (body) {
      body.style.overflow = 'hidden';
    }
    if (preReqDesc) {
      preReqDesc.innerHTML = selectedCard.mCourseDescription;
    }
  }, []);

  // Allows scrolling after modal is closed.
  const closeModal = () => {
    setShowModal(false);
    const body = document.querySelector('body');
    if (body) {
      body.style.overflow = 'auto';
    }
  };

  const generateTimeDuration = (timeDurationInMinutes: string) => {
    const timeDuration: number = Number(timeDurationInMinutes);
    if (timeDuration < 60) {
      return `${timeDuration} minutes`;
    } else {
      const hours = Math.floor(timeDuration / 60);
      const minutes = (timeDuration / 60 - Math.floor(timeDuration / 60)) * 60;

      // Logic to not display 0 minutes and for using the word hour instead of hours for 1 hour.
      if (minutes > 0) {
        if (hours <= 1) {
          return `${hours} hour and ${minutes} minutes`;
        } else {
          return `${hours} hours and ${minutes} minutes`;
        }
      } else {
        if (hours <= 1) {
          return `${hours} hour`;
        } else {
          return `${hours} hours`;
        }
      }
    }
  };

  return (
    <>
      <div id='modal-overlay' onClick={() => closeModal()}></div>
      <div
        id='modal-box'
        style={{
          position: 'fixed',
          top: `${modalPositionY}px`,
        }}
      >
        <div id='modal-body'>
          <button type='button' id='modal-button' onClick={() => closeModal()}>
            <CloseIcon sx={{ height: '20px', width: '20px' }} />
          </button>
          <h2>
            {selectedCard.sCourseNumber} {selectedCard.sCourseName}
          </h2>
          <p className='modal-description' id='modal-description'>
            {selectedCard.mCourseDescription}
          </p>
          {selectedCard.sCourseMinutes ? (
            <p>
              <span className='modal-section-header'>Course Duration: </span>
              {generateTimeDuration(selectedCard.sCourseMinutes)}
              {/* {selectedCard.sCourseMinutes} minutes */}
            </p>
          ) : null}
          {selectedCard.sPreReqNumbers ? (
            <p>
              <span className='modal-section-header'>Prerequisites: </span>
              {selectedCard.sPreReqNumbers}
            </p>
          ) : null}
          {selectedCard.sDivisions ? (
            <p>
              <span className='modal-section-header'>Division: </span>
              {selectedCard.sDivisions}
            </p>
          ) : null}
          {selectedCard.sCrossCreditNumbers ? (
            <p>
              <span className='modal-section-header'>Cross Credit: </span>
              {selectedCard.sCrossCreditNumbers}
            </p>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Modal;
